import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs';
import { AlertService } from '../_services/alert.service';

@Component({
  selector: 'alert',
  template: '<div *ngIf="message" [ngClass]="message.css">{{message.text}}<button type="button" class="close" (click)="dismissAlert()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>'
})
export class AlertComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  message: any;

  constructor(private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.subscription = this.alertService.getAlert().subscribe(message => {
      switch (message && message.type) {
        case 'success':
          message.css = 'alert alert-success';
          break;
        case 'error':
          message.css = 'alert alert-danger';
          break;
      }

      this.message = message;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  dismissAlert(): void {
    this.alertService.clear();
  }

}
