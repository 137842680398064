import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { LayoutComponent } from './_components/layout/layout.component';
import { AppRoutingModule } from './app-routing.module';
import { AlertService } from './_services/alert.service';
import { AlertComponent } from './_components/alert.component';

@NgModule({
  declarations: [
    AlertComponent,
    AppComponent,
    LayoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [
    AlertService,
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
