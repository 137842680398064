import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './_components/layout/layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./_views/index/index.module').then(m => m.IndexModule)
      },
      {
        path: 'error',
        loadChildren: () => import('./_views/error/error.module').then(m => m.ErrorModule)
      },
      {
        path: 'legal',
        loadChildren: () => import('./_views/legal/legal.module').then(m => m.LegalModule)
      },
      {
        path: 'support',
        loadChildren: () => import('./_views/support/support.module').then(m => m.SupportModule)
      },
    ]
  },
  { path: '**', redirectTo: '/error/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: [],
  providers: [],
})
export class AppRoutingModule { }
